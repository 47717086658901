<template>
    <div>
        <!-- main page -->
        <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : SO Code or Customer Name" />
                        </div>
                    </div>
                </div>

                <vs-table :data="dataSalesOrder" stripe border>
                    <template slot="thead">
                        <vs-th>
                            <div class="vx-row mb-12">Sales Order</div>
                        </vs-th>
                        <vs-th>
                            <div class="vx-row mb-12">Customer</div>
                        </vs-th>
                        <vs-th>
                            <div class="vx-row mb-12">Company</div>
                        </vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr :key="indexSO" v-for="(dt, indexSO) in data">
                            <vs-td>
                                <vs-button size="[detail ? 'small' : '', '']" v-on:click="clickSOCode(dt)" color="primary" type="gradient">
                                    {{dt.Code}}
                                </vs-button>
                                <br><br>
                                Source : {{dt.Source}} <br>
                                Sales : <b>{{dt.Salesman.Name}}</b> <br>
                                Channel : ({{dt.SalesChannel.Code}}) {{dt.SalesChannel.Name}} <br>
                                Delivery Date : {{dateFormat(dt.DueDate)}} <br>
                                Total Line : {{dt.SalesOrderLine.length}} <br>
                                Notes : {{dt.Notes}}
                            </vs-td>
                            <vs-td>
                                Category : {{dt.Customer.CustomerCategory.Name}} <br>
                                Code : {{dt.Customer.Code}} <br>
                                Name : {{dt.CustomerName}} <br>
                                Bill to : {{dt.BillAddress.Address}}, {{dt.BillAddress.City}}, {{dt.BillAddress.Country}}<br>
                                Ship to : {{dt.ShipAddress.Address}}, {{dt.ShipAddress.City}}, {{dt.ShipAddress.Country}}<br>
                                Payment term : {{dt.PaymentTerm.Name}}
                            </vs-td>
                            <vs-td>
                                Code : {{dt.Company.Code}} <br>
                                Name : {{dt.Company.Name}}
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
        
                <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
            </div>
        </div>
        <vs-popup fullscreen title="Sales Order Lines" :active.sync="detail">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <detailSelectedSo :selectedSO="selectedSO"></detailSelectedSo>
                </div>
            </div>
        </vs-popup>
    </div>
</template>


<script>
import detailSelectedSo from "./detail_selected_so.vue";
import moment from 'moment'

export default {
    components: {
        detailSelectedSo
    },
    props: 
        ["territory", "startDate", "endDate"],
        mounted() {
            this.selectedSO = this.dataSalesOrder
        },
        data() {
            return {
                selectedSO: {},
                dataSalesOrder: [{
                    SalesOrderLine: [{}],
                    Customer: {
                        Code: "",
                        Name: "",
                        Address: "",
                        Phone: "",
                        CustomerCategory: {
                            Name: ""
                        }
                    },
                    Company: {
                        Code: "",
                        Name: "",
                    },
                    SalesChannel: {
                        Code: "",
                        Name: ""
                    },
                    BillAddress: {
                        Address: "",
                        City: "",
                        Country: "",
                    },
                    ShipAddress: {
                        Address: "",
                        City: "",
                        Country: "",
                    },
                    Salesman: {
                        Name: "",
                    },
                    PaymentTerm: {
                        Name: "",
                    }
                }],
                salesOrderCode: "",

                limits: [10, 25, 50, 100, "All"],
                limitShow: 10,
                drawData: 0,
                isActive: 1,
                modelCheck: [],
                search: "",
                detailShow: "core vx-col md:w-1/2 w-full mb-base ",
                detailHide: "core vx-col md:w-1/1 w-full mb-base ",
                detail: false,
                recordsTotal: 0,
                maxPage: 2,
                dataEnd: 0,
            };
        },
    watch: {
        territory: function() {
            this.getData();
        }, 
        search: function() {
            this.getData();
        },
        startDate: function() {
            this.getData();
        },
        endDate: function() {
            this.getData();
        }
    },
    computed: {
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                this.page(page);
            }
        }
    },
    methods: {
        dateFormat(date) {
            if (date) {
                return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
            }
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        getSOLine () {
            const search = this.salesOrderCode;
            if (search) {
                this.selectedSO = this.dataSalesOrder.filter(so => so.Code.indexOf(search) > -1)[0] // filter + get 1st record
            }
        },

        clickSOCode(dt) {
            this.closeDetail();
            setTimeout(function() {}, 3000);
            this.salesOrderCode = dt.Code;
            this.getSOLine();
            this.detail = true;
            this.scrollToTop();
        },

        closeDetail() {
            this.detail = false;
        },

        page(page) {
            this.getData(page);
            return page;
        },

        changeLimit(limit) {
            this.limitShow = limit;
            this.getData(this.isActive);
        },

        getData(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/sales-order", {
                params: {
                    territory_code: this.territory,
                    start_created_at: this.startDate,
                    end_created_at: this.endDate,
                    approval_status: 1,
                    approval_manager: 1,
                    approval_release: 1,
                    validation_status: 1,
                    allocation_status: 0,
                    search: this.search,
                    limit: this.limitShow,
                    offset: this.limitShow * (page - 1)
                }
            }).then(resp => {
                // console.log(resp.data);
                if (resp.code == 200) {
                    this.drawData = this.drawData + 1;
                    this.dataSalesOrder = resp.data.SalesOrders;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                    this.checkedAll = false;
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                this.$vs.loading.close();
            });
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
<template>
    <div class="vx-col w-11/12">
        <div class="vx-row mb-12" style="width:100%">
			<div class="vx-col sm:w-1/3 w-full">
				<span>Sales Order</span>
			</div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="salesOrder.Code" disabled />
            </div>
		</div>
        <div class="vx-row mb-6" style="width:100%">
            <div class="vx-col sm:w-1/3 w-full">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">
                            {{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}
                        </span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                            <span>{{item}}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>
            <div class="mx-auto"></div>
            <div class="vx-col sm:w-1/3 w-full">
                <div class="flex flex-wrap items-center justify-between">
                    <vs-input class="mb-4 md:mb-0 mr-4" v-model="search" placeholder="Search..." />
                </div>
            </div>
        </div>

        <div class="vx-row mb-12" style="width:100%">
            <!-- TABLE ACTION ROW -->
            <vs-table :data="data" stripe style="width:100%">
                <template slot="thead">
                    <vs-th>Name</vs-th>
                    <vs-th>Qty</vs-th>
                    <vs-th>Price</vs-th>
                    <vs-th>Subtotal</vs-th>
                    <vs-th>Disc</vs-th>
                    <vs-th>Line Total</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :key="index" v-for="(dt, index) in data">
                        <vs-td style="text-align:left">{{dt.ItemName}}</vs-td>
                        <vs-td style="text-align:right">{{dt.Quantity}} ({{dt.ItemUnit}})</vs-td>
                        <div v-if="dt.SalesOrderLinePrice.length > 0">
                            <vs-td style="text-align:right">step-ladder</vs-td>
                        </div>
                        <div v-if="dt.SalesOrderLinePrice.length == 0">
                            <vs-td style="text-align:right">{{formatCurrency(dt.Price)}}</vs-td>
                        </div>
                        <vs-td style="text-align:right">{{formatCurrency(dt.Subtotal)}}</vs-td>
                        <vs-td style="text-align:right">{{formatCurrency(dt.Discount)}}</vs-td>
                        <vs-td style="text-align:right">{{formatCurrency(dt.Total)}}</vs-td>
                    </vs-tr>
                </template>
            </vs-table>

            <!-- PAGINATE -->
            <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPageDetail" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
        </div>
        <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
            <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                <span>Total Order</span>
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalBrutto" disabled />
            </div>
        </div>
        <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
            <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                <span>Total Discount</span>
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalDiscount" disabled />
            </div>
        </div>
        <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
            <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                <span>Total Nett</span>
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalNetto" disabled />
            </div>
        </div>
        <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
            <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                <span>Tax</span>
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalTax" disabled />
            </div>
        </div>
        <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
            <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                <span>Charges</span>
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalCharge" disabled />
            </div>
        </div>
        <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
            <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                <span>Total</span>
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalBilling" disabled />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["selectedSO"],
    created(){
        // console.log("created")
    },
    mounted(){
        this.salesOrder = this.selectedSO;
        this.salesOrderLine = this.salesOrder.SalesOrderLine;
        this.data = this.salesOrder.SalesOrderLine;
        this.loadDatatable();

        this.totalBrutto = this.formatCurrency(this.salesOrder.Subtotal); // total order = harga satuan dikali jumlah 
        this.totalDiscount = this.formatCurrency(this.salesOrder.Discount); // total diskon = totalnya diskon
        this.totalNetto = this.formatCurrency(this.salesOrder.Subtotal - this.salesOrder.Discount); // total nett = total order - total diskon
        this.totalTax = this.formatCurrency(this.salesOrder.TaxAmount); // total pajak = total nett * pajak
        this.totalCharge = this.formatCurrency(this.salesOrder.SubtotalCharge); // total charge
        this.totalBilling = this.formatCurrency(this.salesOrder.Total); // total bayar = total nett + total pajak + total charge
    },
    watch: {
        selectedSO() {
            this.salesOrder = this.selectedSO;
            this.salesOrderLine = this.salesOrder.SalesOrderLine;
            this.data = this.salesOrder.SalesOrderLine;
            this.loadDatatable();

            this.totalBrutto = this.formatCurrency(this.salesOrder.Subtotal); // total order = harga satuan dikali jumlah 
            this.totalDiscount = this.formatCurrency(this.salesOrder.Discount); // total diskon = totalnya diskon
            this.totalNetto = this.formatCurrency(this.salesOrder.Subtotal - this.salesOrder.Discount); // total nett = total order - total diskon
            this.totalTax = this.formatCurrency(this.salesOrder.TaxAmount); // total pajak = total nett * pajak
            this.totalCharge = this.formatCurrency(this.salesOrder.SubtotalCharge); // total charge
            this.totalBilling = this.formatCurrency(this.salesOrder.Total); // total bayar = total nett + total pajak + total charge
        },
        search: function() {
            this.filterSOLine();
        }
    },
    computed: {
        currentPageDetail: {
            get() {
                return 1;
            },
            set(page) {
                this.pageDetail(page);
            }
        },
        // totalSOLine: function(){
        //     let totalSOLine = 0;
        //     Object.entries(this.data).forEach(([, val]) => {
        //         totalSOLine = totalSOLine + val.Total;                
        //     });
        //     return totalSOLine.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        // },
        // totalDiscount: function(){
        //     let totalDiscount = 0;
        //     Object.entries(this.data).forEach(([, val]) => {
        //         totalDiscount = totalDiscount + val.Discount
        //     });
        //     return totalDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        // },
    },
    data: () => ({
        limits: [5,10, 25, 50],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        checked: [],
        modelCheck: [],
        checkedAll: false,
        search: "",
        detailShow: "core vx-col md:w-1/2 w-full mb-base",
        detailHide: "core vx-col md:w-1/1 w-full mb-base",
        detail: false,
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
        data: [{}],
        salesOrder: {},
        salesOrderLine: [{}],

        totalBrutto: 0,
        totalDiscount: 0,
        totalNetto: 0,
        totalTax: 0,
        totalCharge: 0,
        totalBilling: 0,
    }),
    methods: {
        pageDetail(page) {
            this.loadDatatable(page);
            return page;
        },
        changeLimit(page) {
            this.limitShow = page;
            this.loadDatatable(this.isActive);
        },
        loadDatatable(page=1) {
            this.isActive = page;

            this.drawData = this.drawData + 1;
            this.recordsTotal = (typeof this.data) != 'undefined' ? this.data.length : 0;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = (typeof this.data) != 'undefined' ? this.data.length : 0;
        },
        filterSOLine() {
            if(this.search) {
                this.data = this.salesOrderLine.filter(sol => sol.ItemName.indexOf(this.search) > -1) // filter + get 1st record
            } else {
                this.data = this.salesOrderLine
            }
        },
        subtotal(price, qty) {
            let priceNett = 0;
            priceNett = price * qty;
            return priceNett.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatCurrency(nominal) {
            if(nominal) {
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        formatNumber(string) {
            if(string) {
                return parseFloat(string.toString().replace(/\./g, ""));
            } else {
                return 0;
            }
        }
    }
}

</script>